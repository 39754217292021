<template lang="">
  <Card>
    <div class="row">
      <div class="col-12 m-0 p-0">
        <div class="form-group">
          <label style="color: #2196f3" for="banka">Banka </label>
          <select class="form-control" id="exampleFormControlSelect1">
            <option value="" selected>Seçiniz</option>
            <option>Şekerbank</option>
            <option>Odebank</option>
            <option>Vakıfbank</option>
            <option>Alternatif Bank</option>
            <option>Alternatif Leasing</option>
            <option>Anadolubank</option>
            <option>Burgan Bank</option>
            <option>Fibabanka</option>
            <option>İstanbul Varlık</option>
            <option>Perabank</option>
            <option>TÜRKİYE FİNANS KATILIM BANKASI A.Ş.</option>
            <option>ING BANK A.Ş.</option>
            <option>Pie Portföy Yönetimi A.Ş.</option>
            <option>Sahibinden</option>
            <option>Murat Eser</option>
            <option>ZİYA EREN</option>
            <option>TÜRKİYE İŞ BANKASI</option>
            <option>TÜRKİYE EMLAK KATILIM BANKASI A.Ş.</option>
            <option>YAPI VE KREDİ BANKASI A.Ş</option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div>
          <b-form-checkbox  name="check-button" switch>
            Tümünü Seç/Kaldır
          </b-form-checkbox>
          <b-form-checkbox  name="check-button" switch>
            Varlıklar (Kaydet)
          </b-form-checkbox>
          <b-form-checkbox  name="check-button" switch>
            Varlıklar (Notlar)
          </b-form-checkbox>
          <b-form-checkbox  name="check-button" switch>
            Varlıklar (Yönetim)
          </b-form-checkbox>
        </div>
      </div>
    </div>
    <button class="saveButton mx-auto mt-5">Kaydet</button>
  </Card>
</template>
<script>
export default {};
</script>
